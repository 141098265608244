import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { useAtom } from 'jotai';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextBlock,
  TextRow,
  MediaBlock,
  RectShape,
} from 'react-placeholder/lib/placeholders';
import ReactPlaceholder from 'react-placeholder';
import { dosenData } from '../jotai';

import { copyTextToClipboard } from '../utils';

import {
  Box,
  Flex,
  Button,
  Text,
  IconButton,
  Icon,
  useToast,
} from '@chakra-ui/react';

import './Kelas.css';

import Header from '../Components/Header';

import { BiRefresh, BiCheck } from 'react-icons/bi';
import { FaRegCopy } from 'react-icons/fa';
import { MdOpenInNew } from 'react-icons/md';

const api = process.env.REACT_APP_API;

function Kelas() {
  const toast = useToast();

  const params = useParams();
  const navigate = useNavigate();
  const [dosen] = useAtom(dosenData);

  const [datakelas, setDatakelas] = useState(null);

  useEffect(() => {
    // kalo belom login arahkan ke halaman login
    if (!dosen) {
      navigate('/login');
    }
  }, [dosen]);

  const detailkelas = useQuery(
    `detailkelas-${params?.id}`,
    () => {
      return axios.get(`${api}/detailkelas/${params?.id}`);
    },
    {
      enabled: !!params?.id,
    }
  );

  const refreshMhs = useMutation((jadwal_id, permatasari) => {
    // console.log('refreshMhs', jadwal_id);
    const url_refresh = permatasari
      ? `${api}/refresh_permatasari/${dosen?.user?.nip}/${jadwal_id}`
      : `${api}/refreshmahasiswa/${dosen?.user?.nip}/${jadwal_id}`;
    return axios.get(url_refresh);
  });

  useEffect(() => {
    if (detailkelas && detailkelas.data && detailkelas.data.data) {
      setDatakelas(detailkelas?.data?.data);
    }
  }, [detailkelas]);

  useEffect(() => {
    if (refreshMhs && refreshMhs.data && refreshMhs.data.data) {
      setDatakelas(refreshMhs?.data?.data);
    }
  }, [refreshMhs]);

  // const datakelas = detailkelas?.data?.data;
  console.log(datakelas);
  return (
    <Flex w="100%" direction="column">
      <Header />
      <Box
        pl={[8, 16]}
        pr={[8, 16]}
        pb={10}
        w="100%"
        margin={[, , 'auto']}
        maxW={'1440px'}
      >
        <Flex justify="space-between" align="flex-start" mt="16px" wrap="wrap">
          <Box minW={48} mb={['12px', '0px']}>
            <ReactPlaceholder
              showLoadingAnimation={true}
              ready={!detailkelas.isLoading}
              rows={2}
            >
              <Text fontWeight="bold" fontSize="sm" lineHeight="shorter">
                {datakelas?.matakuliah} {datakelas?.Kelas}{' '}
                {datakelas?.permatasari && '(Permatasari)'}
              </Text>
              <Text fontSize="xs" lineHeight="none">
                Program Studi {datakelas?.prodi} {datakelas?.program}
              </Text>
              {datakelas?.team_teaching && (
                <Text fontSize="xs" lineHeight="none">
                  Kelas ini merupakan kelas{' '}
                  <span style={{ fontWeight: 'bold' }}>Team Teaching</span>
                </Text>
              )}
            </ReactPlaceholder>
          </Box>
          <Flex
            className="info-kanan"
            justify="flex-start"
            align="center"
            wrap="wrap"
          >
            <Button
              leftIcon={<MdOpenInNew />}
              colorScheme="blue"
              variant="ghost"
              fontSize="sm"
              fontWeight="thin"
              disabled={detailkelas.isLoading}
              onClick={() => {
                window.open(datakelas?.link_kelas, '_blank').focus();
              }}
            >
              Buka Classroom
            </Button>
            <Flex
              py="6px"
              pr="7px"
              pl="12px"
              borderRadius="base"
              borderWidth="1px"
            >
              <Box>
                <Text fontSize="sm" lineHeight="shorter">
                  Kode Kelas
                </Text>

                <ReactPlaceholder
                  showLoadingAnimation={true}
                  ready={!detailkelas.isLoading}
                  rows={1}
                >
                  <Text fontWeight="bold" fontSize="sm" lineHeight="shorter">
                    {datakelas?.kode_kelas?.toUpperCase()}
                  </Text>
                </ReactPlaceholder>
              </Box>
              <IconButton
                aria-label="Copy kode kelas"
                size="md"
                fontSize="lg"
                variant="ghost"
                color="current"
                marginLeft="2"
                icon={<FaRegCopy />}
                disabled={detailkelas.isLoading}
                onClick={() => {
                  copyTextToClipboard(datakelas?.kode_kelas);
                  toast({
                    title: `Berhasil disalin ke clipboard`,
                    position: 'top-right',
                    duration: 2000,
                  });
                }}
              />
            </Flex>
          </Flex>
        </Flex>

        <Box className="mahasiswa" mt="18px">
          <Flex justify="space-between" align="center" wrap="wrap">
            <Flex
              justify="flex-start"
              align="center"
              wrap="wrap"
              className="mahasiswa-header-kiri"
            >
              <Text fontWeight="bold" fontSize="sm" lineHeight="shorter">
                Pengajar
              </Text>

              {datakelas && datakelas?.mahasiswa && (
                <Text fontSize="xs" lineHeight="none">
                  {datakelas?.team_teaching ? '(Team Teaching)' : ''}
                </Text>
              )}
            </Flex>
          </Flex>

          <Box mt="22px">
            {detailkelas.isLoading &&
              [1, 2].map(idx => (
                <Flex
                  key={idx}
                  pt="8px"
                  pb="8px"
                  justify="space-between"
                  align="center"
                  wrap="wrap"
                  borderBottomWidth="1px"
                >
                  <Flex justify="center" align="center">
                    <RectShape
                      color="#E0E0E0"
                      style={{ width: 36, height: 36 }}
                    />
                    <Box minW={48}>
                      <TextBlock color="#E0E0E0" rows={2} />
                    </Box>
                  </Flex>
                  <Box ml="48px" minW={64}>
                    <TextRow color="#E0E0E0" />
                  </Box>
                </Flex>
              ))}

            {datakelas &&
              datakelas?.pengajar &&
              datakelas?.pengajar?.map((dsn, idx) => {
                if (!dsn.dosen) return null;
                return (
                  <Flex
                    key={idx}
                    pt="8px"
                    pb="8px"
                    justify="space-between"
                    align="center"
                    wrap="wrap"
                    borderBottomWidth="1px"
                  >
                    <Flex justify="center" align="center">
                      <Icon as={BiCheck} mr="18px" w={8} h={8} />
                      <Box>
                        <Text fontWeight="bold" fontSize="sm">
                          {dsn.dosen}
                        </Text>
                        <Text fontSize="xs">
                          {dsn.nip} {dsn.email ? ` - ${dsn.email}` : ''}
                        </Text>
                      </Box>
                    </Flex>
                    <Box ml="48px">
                      <Text fontSize="xs">
                        {dsn.sudah_gabung ? 'Sudah bergabung' : ''}
                      </Text>
                    </Box>
                  </Flex>
                );
              })}
            {!datakelas?.pengajar.length && (
              <Text ml={8}>Tidak ada pengajar di kelas.</Text>
            )}
          </Box>
        </Box>

        <Box className="mahasiswa" mt="18px">
          <Flex justify="space-between" align="center" wrap="wrap">
            <Flex
              justify="flex-start"
              align="center"
              wrap="wrap"
              className="mahasiswa-header-kiri"
            >
              <Text fontWeight="bold" fontSize="sm" lineHeight="shorter">
                Mahasiswa
              </Text>

              {datakelas && datakelas?.mahasiswa && (
                <Text fontSize="xs" lineHeight="none">
                  (
                  {datakelas?.mahasiswa.length +
                    datakelas?.mahasiswa_permatasari.length}{' '}
                  Mahasiswa)
                </Text>
              )}
            </Flex>
            <Flex className="mahasiswa-header-kanan">
              <Button
                leftIcon={<BiRefresh />}
                colorScheme="blue"
                variant="ghost"
                fontSize="sm"
                fontWeight="thin"
                disabled={
                  detailkelas.isLoading || refreshMhs.isLoading || !params?.id
                }
                isLoading={refreshMhs.isLoading}
                onClick={() => {
                  refreshMhs.mutate(params?.id);
                }}
              >
                Refresh Mahasiswa
              </Button>
            </Flex>
          </Flex>

          <Box mt="22px">
            {detailkelas.isLoading &&
              [1, 2, 3, 4, 5, 6, 7, 8, 9].map(idx => (
                <Flex
                  key={idx}
                  pt="8px"
                  pb="8px"
                  justify="space-between"
                  align="center"
                  wrap="wrap"
                  borderBottomWidth="1px"
                >
                  <Flex justify="center" align="center">
                    <RectShape
                      color="#E0E0E0"
                      style={{ width: 36, height: 36 }}
                    />
                    <Box minW={48}>
                      <TextBlock color="#E0E0E0" rows={2} />
                    </Box>
                  </Flex>
                  <Box ml="48px" minW={64}>
                    <TextRow color="#E0E0E0" />
                  </Box>
                </Flex>
              ))}
            {datakelas &&
              datakelas?.mahasiswa_permatasari &&
              datakelas?.mahasiswa_permatasari?.map((mahasiswa, idx) => (
                <Flex
                  key={idx}
                  pt="8px"
                  pb="8px"
                  justify="space-between"
                  align="center"
                  wrap="wrap"
                  borderBottomWidth="1px"
                >
                  <Flex justify="center" align="center">
                    <Icon as={BiCheck} mr="18px" w={8} h={8} />
                    <Box>
                      <Text fontWeight="bold" fontSize="sm">
                        {mahasiswa.nama}
                      </Text>
                      <Text fontSize="xs">{mahasiswa.nim} (Permatasari)</Text>
                    </Box>
                  </Flex>
                  <Box ml="48px">
                    <Text fontSize="xs">{mahasiswa.email}</Text>
                  </Box>
                </Flex>
              ))}
            {datakelas &&
              datakelas?.mahasiswa &&
              datakelas?.mahasiswa?.map((mahasiswa, idx) => (
                <Flex
                  key={idx}
                  pt="8px"
                  pb="8px"
                  justify="space-between"
                  align="center"
                  wrap="wrap"
                  borderBottomWidth="1px"
                >
                  <Flex justify="center" align="center">
                    <Icon as={BiCheck} mr="18px" w={8} h={8} />
                    <Box>
                      <Text fontWeight="bold" fontSize="sm">
                        {mahasiswa.nama}
                      </Text>
                      <Text fontSize="xs">{mahasiswa.nim}</Text>
                    </Box>
                  </Flex>
                  <Box ml="48px">
                    <Text fontSize="xs">{mahasiswa.email}</Text>
                  </Box>
                </Flex>
              ))}
            {!datakelas?.mahasiswa.length && (
              <Text ml={8}>Tidak ada mahasiswa di kelas.</Text>
            )}
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}

export default Kelas;
