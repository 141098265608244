import { Box, Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function KelasAktifCardItem({ kls }) {
  const navigate = useNavigate();

  return (
    <Box
      pt="12px"
      pr="16px"
      pb="18px"
      pl="22px"
      borderWidth="1px"
      borderRadius="lg"
      cursor="pointer"
      _hover={{ shadow: 'md' }}
      onClick={() => {
        navigate(`/kelas/${kls.jadwal_id}`);
      }}
    >
      <Flex
        justify="flex-end"
        alignItems="flex-end"
        mb="48px"
        direction="column"
      >
        <Text fontSize="xs" color="gray.300">
          {kls.kode_matakuliah}
        </Text>
        {kls.permatasari && <Text fontSize="xs">Permatasari</Text>}
        {kls.team_teaching && <Text fontSize="xs">Team Teaching</Text>}
      </Flex>
      <Text fontWeight="bold" fontSize="sm">
        {kls.matakuliah} Kelas {kls.kelas}
      </Text>
      <Text fontSize="xs">{kls.prodi}</Text>
      <Text fontSize="xs">{kls.program}</Text>
    </Box>
  );
}

export default KelasAktifCardItem;
