import { useState } from 'react';
import {
  Box,
  Image,
  Flex,
  Button,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import axios from 'axios';

import ReactPlaceholder from 'react-placeholder';

import { useMutation, useQuery } from 'react-query';

// import iconEmail from '../assets/icon-email.png';
// import iconKey from '../assets/icon-key.png';
import iconNewtab from '../assets/icon-newtab.png';

import illust2 from '../assets/illust2.png';

const api = process.env.REACT_APP_API;

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function DosenCard({ detaildosen, dosen, setDosen }) {
  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [gagalGantiEmail, setGagalGantiEmail] = useState(false);

  const {
    data,
    error,
    isError,
    isLoading,
    mutate: gantiEmail,
  } = useMutation(
    async () => {
      // console.log('update email dosen');
      return await axios.post(`${api}/ganti-email/`, {
        nip: dosen?.user?.nip,
        email,
      });
    },
    {
      onSuccess: res => {
        // console.log(res);
        // console.log(dosen);
        if (
          res.data &&
          (res.data?.gagal || res.data?.status != 'berhasil-ganti-email')
        ) {
          setGagalGantiEmail(true);
        } else if (
          res.data &&
          (!res.data?.gagal || res.data?.status == 'berhasil-ganti-email')
        ) {
          setGagalGantiEmail(false);
          let dosennya = { ...dosen };
          dosennya.user.email_dosen = email;
          dosennya.user.user_status = 'oke';
          setDosen(dosennya);
        }
        // const result = {
        //   status: res.status + '-' + res.statusText,
        //   headers: res.headers,
        //   data: res.data,
        // };
      },
      onError: err => {
        setGagalGantiEmail(true);
        console.trace(err);
      },
    }
  );

  if (dosen?.user?.user_status == 'tidak-ada-email') {
    return (
      <Flex
        mt={4}
        borderWidth="1px"
        borderColor="orange.100"
        rounded="lg"
        justify="space-between"
        align="center"
      >
        <Box py="54px" px="31px">
          <Text fontSize="xs">Selamat Datang,</Text>
          <Text fontSize="md" fontWeight="bold">
            {dosen?.user?.nama}
          </Text>
          <Text fontSize="md" fontWeight="bold" color="orange.400">
            Tidak terdapat info email anda pada sistem!
          </Text>
          <Text fontSize="xs" mt="4">
            Mohon tuliskan email Universitas Tanjungpura anda:
          </Text>
          {emailInvalid && (
            <Alert status="error" mt="4">
              <AlertIcon />
              <AlertTitle>Email yang diberikan tidak valid!</AlertTitle>
              <AlertDescription>
                Mohon periksa kembali email yang ada masukkan.
              </AlertDescription>
            </Alert>
          )}
          {gagalGantiEmail && (
            <Alert status="error" mt="4">
              <AlertIcon />
              <AlertTitle>Gagal mengganti email!</AlertTitle>
              <AlertDescription>
                Terjadi kesalahan, mohon coba lagi atau hubungi helpdesk.
              </AlertDescription>
            </Alert>
          )}
          <Flex mt="4" wrap={{ base: 'wrap', md: 'nowrap' }}>
            <InputGroup mr="2" mb="2" minW="64">
              <InputLeftElement
                pointerEvents="none"
                children={<EmailIcon color="gray.300" />}
              />
              <Input
                type="email"
                placeholder="Email Universitas Tanjungpura Anda"
                value={email}
                onChange={event => {
                  setEmailInvalid(false);
                  setGagalGantiEmail(false);

                  setEmail(event.target.value);
                }}
              />
            </InputGroup>
            <Button
              colorScheme="blue"
              variant="solid"
              fontSize="xs"
              fontWeight="normal"
              onClick={() => {
                if (validateEmail(email)) {
                  gantiEmail();
                } else {
                  setEmailInvalid(true);
                }
              }}
            >
              Simpan
            </Button>
          </Flex>
        </Box>

        <Image className="board-illust" src={illust2} w={64} h={56} />
      </Flex>
    );
  }

  return (
    <Flex
      mt={4}
      borderWidth="1px"
      rounded="lg"
      justify="space-between"
      align="center"
    >
      <Box py="54px" px="31px">
        <Text fontSize="xs">Selamat Datang,</Text>
        <ReactPlaceholder
          showLoadingAnimation={true}
          ready={!detaildosen.isLoading}
          rows={2}
        >
          <Text fontSize="md" fontWeight="bold">
            {dosen?.user?.nama}
          </Text>
          <Text fontSize="xs">{dosen?.user?.email_dosen}</Text>
        </ReactPlaceholder>

        <Flex className="board-button-container" mt={3} wrap="wrap">
          {/* <Button
                colorScheme="blue"
                variant="ghost"
                fontSize="xs"
                fontWeight="normal"
                leftIcon={<Image src={iconEmail} />}
              >
                Ganti Email
              </Button>
              <Button
                colorScheme="blue"
                variant="ghost"
                fontSize="xs"
                fontWeight="normal"
                leftIcon={<Image src={iconKey} />}
              >
                Reset Password
              </Button> */}
          <Button
            colorScheme="blue"
            variant="ghost"
            fontSize="xs"
            fontWeight="normal"
            leftIcon={<Image src={iconNewtab} />}
            onClick={() => {
              window.open('https://classroom.google.com', '_blank').focus();
            }}
          >
            Buka Classroom
          </Button>
        </Flex>
      </Box>

      <Image className="board-illust" src={illust2} w={64} h={56} />
    </Flex>
  );
}

export default DosenCard;
