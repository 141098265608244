import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useAtom } from 'jotai';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Flex,
  Button,
  Text,
  Image,
  useColorModeValue,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Link,
} from '@chakra-ui/react';
import { ExternalLinkIcon, WarningTwoIcon } from '@chakra-ui/icons';
import './Modal.css';

import illust3 from '../assets/illust3.png';

import { modalkls, dosenData } from '../jotai';

const api = process.env.REACT_APP_API;

const Property = props => {
  const { label, value, ...flexProps } = props;
  return (
    <Flex
      as="dl"
      direction={{
        base: 'column',
        sm: 'row',
      }}
      px="6"
      py="4"
      _even={{
        bg: useColorModeValue('gray.50', 'gray.600'),
      }}
      {...flexProps}
    >
      <Box as="dt" minWidth="180px">
        {label}
      </Box>
      <Box as="dd" flex="1" fontWeight="semibold">
        {value}
      </Box>
    </Flex>
  );
};

function ModalPreview({ isOpen, onToggle }) {
  const [kls, setKlsModal] = useAtom(modalkls);
  const [dosen] = useAtom(dosenData);
  // console.log(dosen);

  const navigate = useNavigate();
  const { data, error, isError, isLoading, mutate } = useMutation(jadwal_id => {
    // console.log('kelas untuk diaktifkan', jadwal_id);
    return axios.get(`${api}/aktifkankelas/${dosen?.user?.nip}/${jadwal_id}`);
  });

  const aktifkankelaspreview = useQuery(
    `aktifkankelaspreview-${kls?.jadwal_id}`,
    () => {
      return axios.get(
        `${api}/aktifkankelaspreview/${dosen?.user?.nip}/${kls?.jadwal_id}`
      );
    },
    {
      enabled: !!kls?.jadwal_id,
    }
  );

  useEffect(() => {
    if (datakelas?.data?.data) {
      console.log(
        'kelas untuk di aktifkan - preview : ',
        datakelas?.data?.data
      );
    }
  }, [aktifkankelaspreview]);

  useEffect(() => {
    if (!isError && data?.data) {
      navigate(`/kelas/${kls.jadwal_id}`);
    } else {
      console.log(error);
    }
  }, [data, isError]);

  const datakelas = aktifkankelaspreview?.data?.data || {};

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onToggle();
        setKlsModal(false);
      }}
    >
      <ModalOverlay />
      <ModalContent w="560px" maxW="90%" borderRadius="lg">
        <ModalHeader>Pratinjau kelas</ModalHeader>
        <ModalCloseButton />
        <Divider />
        {!aktifkankelaspreview.isLoading ? (
          <>
            <ModalBody>
              <Flex justify="center" align="center" direction="column">
                <Image src={illust3} maxW="146px" maxH="146px" />
              </Flex>
              <Box>
                <Property
                  label="Nama matakuliah"
                  value={datakelas?.matakuliah}
                />
                <Property
                  label="Kode matakuliah"
                  value={datakelas?.kode_matakuliah}
                />
                <Property label="Kelas" value={datakelas?.kelas} />
                <Property label="Program" value={datakelas?.program} />
                <Property
                  label="Jumlah Mahasiswa"
                  value={`${datakelas?.mahasiswa} orang`}
                />
                {datakelas?.sks && (
                  <Property label="Jumlah SKS" value={datakelas?.sks} />
                )}
                <Property
                  label="Program studi"
                  value={`${datakelas?.prodi} - ${datakelas?.jenjang}`}
                />
                <Property label="Periode" value={datakelas?.periode} />

                {datakelas?.pengajar &&
                  datakelas?.pengajar.length > 0 &&
                  datakelas?.pengajar.map((dsn, idx) => {
                    if (!dsn?.dosen) return null;
                    return (
                      <Property
                        label={`Pengajar ${idx + 1}`}
                        key={idx}
                        value={dsn?.dosen}
                      />
                    );
                  })}
              </Box>
              <Text fontSize="sm" my="12px">
                Kelas Google Classroom akan dibuat untuk matakuliah{' '}
                {datakelas?.matakuliah} Kelas {datakelas?.kelas}, mahasiswa yang
                terdaftar pada matakuliah ini akan dimasukkan secara otomatis.
              </Text>

              <Text fontSize="sm" my="12px">
                Menemukan data yang tidak sesuai?{' '}
                <Link
                  href={`https://wa.me/628152100999?text=%23GCR%20%23DataTidakSesuai%0AKelasID%20%3A%20${kls?.jadwal_id}%0A%0ATerdapat%20perbedaan%20data%20pada%20kelas%20ini%2C%20yaitu%20...`}
                  isExternal
                >
                  hubungi helpdesk GCR <ExternalLinkIcon mx="2px" />
                </Link>
              </Text>
              <Flex justify="center" align="center" direction="column">
                <Text fontWeight="bold" fontSize="lg" my="12px">
                  Aktifkan Kelas?
                </Text>
              </Flex>

              {isError && (
                <Alert status="error" mb={8}>
                  <AlertIcon />
                  <AlertTitle mr={2}>Gagal membuat kelas!</AlertTitle>
                  <AlertDescription>
                    Silahkan{' '}
                    <Link
                      href={`https://wa.me/628152100999?text=%23GCR%20%23GagalBuatKelas%0AKelasID%20%3A%20${kls?.jadwal_id}%0A%0ASaya%20gagal%20membuat%20kelas%20ini.%0AKeterangan%20tambahan%20%3A%20...`}
                      isExternal
                    >
                      hubungi helpdesk GCR <ExternalLinkIcon mx="2px" />
                    </Link>
                  </AlertDescription>
                </Alert>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="red"
                variant="outline"
                fontSize="xs"
                fontWeight="thin"
                px="48px"
                py="12px"
                disabled={isLoading}
                mr={3}
                onClick={() => {
                  onToggle();
                }}
              >
                Batal
              </Button>
              <Button
                colorScheme="blue"
                fontSize="xs"
                fontWeight="thin"
                px="48px"
                py="12px"
                isLoading={isLoading}
                isDisabled={dosen?.user?.user_status == 'tidak-ada-email'}
                onClick={() => {
                  mutate(kls.jadwal_id);
                }}
                title={
                  dosen?.user?.user_status == 'tidak-ada-email'
                    ? 'Tidak bisa mengaktifkan kelas tanpa ada email dosen'
                    : 'Aktifkan kelas ini'
                }
              >
                Aktifkan
              </Button>
            </ModalFooter>
          </>
        ) : (
          <Flex justify={'center'} align={'center'} p={8}>
            <Spinner thickness="4px" speed="0.65s" color="blue.500" size="xl" />
          </Flex>
        )}
      </ModalContent>
    </Modal>
  );
}

export default ModalPreview;
