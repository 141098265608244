import { useNavigate } from 'react-router-dom';
import './Header.css';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { IoLogOut } from 'react-icons/io5';
import { RiWhatsappFill } from 'react-icons/ri';
import {
  Flex,
  Text,
  Divider,
  IconButton,
  Icon,
  Button,
  useMediaQuery,
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { dosenData } from '../jotai';

function Header() {
  const [_, setDosen] = useAtom(dosenData);
  const navigate = useNavigate();
  const [smallerThan600] = useMediaQuery('(max-width: 600px)');
  return (
    <>
      <Flex
        className="header"
        py={[3, 6]}
        px={[8, 16]}
        justify="space-between"
        align="center"
        w="100%"
        margin={[, , 'auto']}
        maxW={'1440px'}
      >
        <Text
          fontWeight="bold"
          fontSize="md"
          cursor={'pointer'}
          onClick={() => {
            navigate('/');
          }}
        >
          Google Classroom
        </Text>
        <Flex>
          {smallerThan600 ? (
            <IconButton
              size="md"
              fontSize="lg"
              aria-label="Logout"
              variant="ghost"
              color="current"
              marginLeft="2"
              icon={<Icon as={RiWhatsappFill} w={6} h={6} />}
              onClick={() => {
                window
                  .open(
                    `https://wa.me/628152100999?text=%23GCR%0D%0ASaya+...+dari+...+memiliki+kendala+...`,
                    '_blank'
                  )
                  .focus();
              }}
            />
          ) : (
            <Button
              rightIcon={<RiWhatsappFill />}
              colorScheme="gray"
              variant="outline"
              onClick={() => {
                window
                  .open(
                    `https://wa.me/628152100999?text=%23GCR%0D%0ASaya+...+dari+...+memiliki+kendala+...`,
                    '_blank'
                  )
                  .focus();
              }}
            >
              Helpdesk GCR
            </Button>
          )}
          <ColorModeSwitcher />

          <IconButton
            size="md"
            fontSize="lg"
            aria-label="Logout"
            variant="ghost"
            color="current"
            marginLeft="2"
            icon={<Icon as={IoLogOut} w={6} h={6} />}
            onClick={() => {
              setDosen(false);
            }}
          />
        </Flex>
      </Flex>
      <Divider />
    </>
  );
}

export default Header;
