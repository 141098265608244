import { Box, Flex } from '@chakra-ui/react';
import { TextBlock, TextRow } from 'react-placeholder/lib/placeholders';

function KelasPlaceholderCardItem() {
  return (
    <Box
      pt="12px"
      pr="16px"
      pb="18px"
      pl="22px"
      borderWidth="1px"
      borderRadius="lg"
      cursor="not-allowed"
    >
      <Flex justify="flex-end" mb="48px">
        <TextRow color="#E0E0E0" style={{ width: 50 }} />
      </Flex>
      <TextBlock showLoadingAnimation={true} color="#E0E0E0" rows={3} />
    </Box>
  );
}

export default KelasPlaceholderCardItem;
