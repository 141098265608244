import { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Button,
  Text,
  Image,
  FormLabel,
  InputRightElement,
  InputGroup,
  Input,
  IconButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Link,
  Circle,
} from '@chakra-ui/react';
import {
  ViewIcon,
  ViewOffIcon,
  ExternalLinkIcon,
  WarningTwoIcon,
} from '@chakra-ui/icons';
import { useMutation } from 'react-query';
import axios from 'axios';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import './Login.css';

import { dosenData } from '../jotai';

import untan from '../assets/untan.png';
import illust from '../assets/illust.png';

const api_login = process.env.REACT_APP_LOGIN_API;
const on_maintainance = process.env.REACT_APP_SERVICE_MAINTAINANCE === 'true';
const on_problem = process.env.REACT_APP_SERVICE_PROBLEM === 'true';

function Login() {
  const [, setDosen] = useAtom(dosenData);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [nip, setNip] = useState('');
  const [pass, setPass] = useState('');
  const { data, error, isError, isLoading, mutate } = useMutation(datalogin => {
    return axios.post(`${api_login}/`, datalogin);
  });
  useEffect(() => {
    // catatan soal post data: ketika password error itu emang return isError sebagai true, tapi tuh data error nya tidak memunculkan sesuai pesan dari post nya tapi malah error http nya (kena status kode 409 - conflict), soalnya kalo salah pass tuh dia tetep kasi respon message alasan gagal nya.

    if (!isError && data?.data) {
      const datadosen = data?.data;

      //set data dosen ke jotai lalu navigate ke home
      setDosen(datadosen);
      navigate('/home');
    } else {
      console.log(error);
    }
  }, [data, isError]);

  const error_note = (
    <>
      <Circle size="40px" bg="yellow.500" color="white" mb="12px">
        <WarningTwoIcon />
      </Circle>
      <Text fontWeight="bold" fontSize="md">
        Sedang ada gangguan.
      </Text>
      <Text fontSize="sm" mb={5}>
        Saat ini website sedang tidak dapat digunakan karena adanya gangguan
        pada service. Silakan coba lagi dalam 1x24 jam. Untuk keperluan lebih
        lanjut silahkan{' '}
        <Link
          href="https://wa.me/628152100999?text=%23KendalaGCR+%23ServiceMati%0D%0ASaya+...+dari+...+memiliki+kendala+..."
          isExternal
        >
          hubungi helpdesk GCR <ExternalLinkIcon mx="2px" />
        </Link>
      </Text>
    </>
  );

  const maintainance_note = (
    <>
      <Circle size="40px" bg="yellow.500" color="white" mb="12px">
        <WarningTwoIcon />
      </Circle>
      <Text fontWeight="bold" fontSize="md">
        Sedang dalam pemeliharan rutin.
      </Text>
      <Text fontSize="sm" mb={5}>
        Saat ini website sedang dalam masa pemeliharaan rutin. Silakan coba lagi
        dalam 1x24 jam. Untuk keperluan lebih lanjut silahkan{' '}
        <Link
          href="https://wa.me/628152100999?text=%23KendalaGCR%0D%0ASaya+...+dari+...+memiliki+kendala+..."
          isExternal
        >
          hubungi helpdesk GCR <ExternalLinkIcon mx="2px" />
        </Link>
      </Text>
    </>
  );

  const login_form = (
    <>
      <Text fontWeight="bold" fontSize="2xl" textAlign="center" mb="8px">
        Google Classroom
      </Text>
      <Text fontWeight="bold" fontSize="md">
        Halaman Login
      </Text>
      <Text fontSize="sm" mb={5}>
        Anda belum masuk. Silahkan masuk menggunakan akun SIAKAD anda.
      </Text>
      {isError && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Gagal masuk!</AlertTitle>
          <AlertDescription>Cek login anda.</AlertDescription>
        </Alert>
      )}

      <form>
        <Flex direction="column" justify="center" align="flex-start" mt="8px">
          <FormLabel htmlFor="nip" fontWeight="bold" fontSize="sm" mb="2px">
            NIP :
          </FormLabel>
          <Input
            id="nip"
            type="text"
            placeholder="NIP anda"
            borderRadius="2xl"
            p={6}
            onChange={e => setNip(e.target.value)}
          />
        </Flex>
        <Flex direction="column" justify="center" align="flex-start" mt="8px">
          <FormLabel
            htmlFor="password"
            fontWeight="bold"
            fontSize="sm"
            mb="2px"
          >
            Password :
          </FormLabel>
          <InputGroup>
            <Input
              id="password"
              borderRadius="2xl"
              p={6}
              type={show ? 'text' : 'password'}
              placeholder="Password anda"
              onChange={e => setPass(e.target.value)}
            />
            <InputRightElement p={6}>
              <IconButton
                onClick={() => setShow(!show)}
                aria-label="Show password"
                icon={show ? <ViewOffIcon /> : <ViewIcon />}
              />
            </InputRightElement>
          </InputGroup>
        </Flex>
        <Button
          colorScheme="blue"
          size="lg"
          mt="24px"
          px="64px"
          py="12px"
          fontSize="sm"
          isLoading={isLoading}
          onClick={() => {
            mutate({
              nip,
              password: pass,
            });
          }}
        >
          Login
        </Button>
      </form>
    </>
  );

  const insideBox = on_problem
    ? error_note
    : on_maintainance
    ? maintainance_note
    : login_form;

  return (
    <Box px={['26px', '70px']} py={['22px', '44px']}>
      <Flex justify="flex-start" align="center">
        <Image w="51px" h="51px" src={untan} alt="logo untan" />
        <Box ml="15px">
          <Text fontSize="sm" fontWeight="bold">
            Universitas Tanjungura
          </Text>
          <Text fontSize="xs">Pontianak - Kalimantan Barat</Text>
        </Box>
      </Flex>
      <Flex mt="54px">
        <Flex mt="34px" justify="center" align="center">
          <Image className="illustrasi" src={illust} alt="illustrasi" />
        </Flex>
        <Box
          className="Login-form"
          borderWidth="1px"
          borderRadius="33px"
          ml={['0px', '0px', '0px', '86px']}
          px="44px"
          py="56px"
        >
          {insideBox}
        </Box>
      </Flex>
      <Box mt="68px" textAlign="center">
        <Text fontSize="xs">
          Dikembangkan oleh <b>UPT. Teknologi Informasi dan Komunikasi</b>{' '}
          Universitas Tanjungpura
        </Text>
      </Box>
    </Box>
  );
}

export default Login;
