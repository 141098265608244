import { useEffect, useState } from 'react';
import { Box, Text, Grid, Flex, useDisclosure, Select } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import axios from 'axios';
import './Home.css';

import { dosenData, modalkls } from '../jotai';

import KelasCardItem from '../Components/KelasCardItem';
import KelasAktifCardItem from '../Components/KelasAktifCardItem';
import DosenCard from '../Components/DosenCard';
import KelasPlaceholderCardItem from '../Components/KelasPlaceholderCardItem';

import Header from '../Components/Header';
import ModalPreview from '../Components/ModalPreview';

const api = process.env.REACT_APP_API;
const on_maintainance = process.env.REACT_APP_SERVICE_MAINTAINANCE === 'true';
const on_problem = process.env.REACT_APP_SERVICE_PROBLEM === 'true';

function Home() {
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure();
  const [dosen, setDosen] = useAtom(dosenData);
  const [_, setKlsModal] = useAtom(modalkls);
  const [kelas, setKelas] = useState([]);
  const [kelasFiltered, setKelasFiltered] = useState([]);
  const [tahunAjaranTerpilih, setTahunAjaranTerpilih] = useState(null);
  const [pilihanTahunAjaran, setPilihanTahunAjaran] = useState([]);

  let kelasaktif = kelasFiltered.filter(kls => kls.aktif) || [];
  let kelastidakaktif = kelasFiltered.filter(kls => !kls.aktif) || [];

  const detaildosen = useQuery(
    'detaildosen',
    () => {
      return axios.get(`${api}/emaildosen/${dosen?.user?.nip}`);
    },
    {
      enabled: !!dosen?.user?.nip,
    }
  );
  const matkuldosen = useQuery(
    'matkuldosen',
    () => {
      return axios.get(`${api}/matkuldosen/${dosen?.user?.nip}`);
    },
    {
      enabled: !!dosen?.user?.nip,
    }
  );

  useEffect(() => {
    // kalo belom login arahkan ke halaman login
    if (!dosen) {
      navigate('/login');
    }

    if (on_maintainance || on_problem) {
      setDosen(null);
      navigate('/login');
    }
  }, [dosen]);

  useEffect(() => {
    let daftarKelas = matkuldosen?.data?.data?.kelas;

    if (!daftarKelas) {
      return;
    }

    setKelas(daftarKelas);

    let tahunajaran = daftarKelas.reduce((acc, obj) => {
      const key = obj['periode'];

      return {
        ...acc,
        [key]: {
          periode: obj['periode'],
          tahunajaran: key == 380 ? '2022/2023-Ganjil' : obj['tahunajaran'],
        },
      };
    }, {});

    tahunajaran = Object.values(tahunajaran).sort(
      (a, b) => b.periode - a.periode
    );
    // console.log('tahunajaran', tahunajaran);

    if (tahunajaran.length > 0) {
      setTahunAjaranTerpilih(tahunajaran[0]?.periode ?? null);
    }
    setPilihanTahunAjaran(tahunajaran);
  }, [matkuldosen?.data?.data?.kelas]);

  useEffect(() => {
    // console.log('tahunAjaranTerpilih', tahunAjaranTerpilih);

    let kelasdifilter = [];
    if (tahunAjaranTerpilih != 'semua') {
      kelasdifilter = kelas.filter(kls => kls.periode == tahunAjaranTerpilih);
    } else {
      kelasdifilter = kelas;
    }

    setKelasFiltered(kelasdifilter);

    kelasaktif = kelasdifilter.filter(kls => kls.aktif) || [];
    kelastidakaktif = kelasdifilter.filter(kls => !kls.aktif) || [];
  }, [tahunAjaranTerpilih, kelas]);

  return (
    <Flex direction="column" w="100%">
      <Header />
      <ModalPreview isOpen={isOpen} onToggle={onToggle} />

      <Box
        className="main"
        pl={[8, 16]}
        pr={[8, 16]}
        pb={10}
        w="100%"
        margin={[, , 'auto']}
        maxW={'1440px'}
      >
        <DosenCard
          dosen={dosen}
          detaildosen={detaildosen}
          setDosen={setDosen}
        />

        <Flex mt={6}>
          <Text mr="4">Tahun ajaran : </Text>
          <Select
            maxW={64}
            onChange={evt => setTahunAjaranTerpilih(evt.target.value)}
          >
            <option value="semua">Semua periode</option>
            {pilihanTahunAjaran.map((itm, idx) => (
              <option key={idx} selected={idx == 0} value={itm.periode}>
                {itm.tahunajaran}
              </option>
            ))}
          </Select>
        </Flex>

        <Box mt={6}>
          <Text fontWeight="bold" fontSize="sm" mb={3}>
            Kelas Aktif
          </Text>
          <Grid
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            gap={[2, 5]}
          >
            {matkuldosen.isLoading &&
              [1, 2, 3, 4].map(idx => <KelasPlaceholderCardItem key={idx} />)}
            {kelasaktif.map((kls, idx) => (
              <KelasAktifCardItem kls={kls} key={idx} />
            ))}
            {!kelasaktif.length && <Text ml={8}>Tidak ada kelas</Text>}
          </Grid>
        </Box>

        <Box mt={6}>
          <Text fontWeight="bold" fontSize="sm" mb={3}>
            Kelas Belum Aktif
          </Text>
          <Grid
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            gap={[2, 5]}
          >
            {matkuldosen.isLoading &&
              [1, 2, 3, 4].map(idx => <KelasPlaceholderCardItem key={idx} />)}
            {kelastidakaktif.map((kls, idx) => (
              <KelasCardItem
                kls={kls}
                key={idx}
                setModalOn={() => {
                  // console.log(kls);
                  setKlsModal(kls);
                  onToggle();
                }}
              />
            ))}
            {!kelastidakaktif.length && <Text ml={8}>Tidak ada kelas</Text>}
          </Grid>
        </Box>
      </Box>
    </Flex>
  );
}

export default Home;
